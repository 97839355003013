<template>
<div id="login" class="d-flex justify-content-center align-items-center vh-100">
  <div class="d-flex flex-column" style="width: 23rem;">
    <b-alert v-if="alert" class="text-center" show :variant="alert.type">{{alert.message}}</b-alert>
    <b-card class="px-2 py-2" ref="container">
      <v-validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
          <h2 class="my-3 font-weight-light">Signup</h2>

          <v-validation-provider name="First Name" rules="required" v-slot="{ errors }">
            <b-form-group label-for="firstname">
              <b-input-group>
                <b-form-input id="firstname" v-model.trim="form.firstname" type="text" :state="errors[0] ? false : null" placeholder="First Name"></b-form-input>
              </b-input-group>
              <b-form-invalid-feedback :state="errors[0] ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </v-validation-provider>

          <v-validation-provider name="Last Name" rules="required" v-slot="{ errors }">
            <b-form-group label-for="lastname">
              <b-input-group>
                <b-form-input id="lastname" v-model.trim="form.lastname" type="text" :state="errors[0] ? false : null" placeholder="Last Name"></b-form-input>
              </b-input-group>
              <b-form-invalid-feedback :state="errors[0] ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </v-validation-provider>

          <v-validation-provider name="Email" rules="required|email" v-slot="{ errors }">
            <b-form-group label-for="username">
              <b-input-group>
                <b-form-input id="username" v-model.trim="form.email" type="email" :state="errors[0] ? false : null" placeholder="Email"></b-form-input>
              </b-input-group>
              <b-form-invalid-feedback :state="errors[0] ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </v-validation-provider>

          <v-validation-provider name="Password" rules="required|min:8" v-slot="{ errors }" vid="confirmPassword">
            <b-form-group label-for="password">
              <b-input-group>
                <b-form-input id="password" v-model.trim="form.password" :state="errors[0] ? false : null" placeholder="Password" type="password"></b-form-input>
              </b-input-group>
              <b-form-invalid-feedback :state="errors[0] ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </v-validation-provider>

          <v-validation-provider name="Confirm Password" rules="required|min:8|confirmed:confirmPassword" v-slot="{ errors }">
            <b-form-group label-for="confirm-password">
              <b-input-group>
                <b-form-input id="confirm-password" v-model.trim="form.confirmPassword" :state="errors[0] ? false : null" placeholder="Confirm Password" type="password"></b-form-input>
              </b-input-group>
              <b-form-invalid-feedback :state="errors[0] ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </v-validation-provider>

          <b-form-group class="text-center">
            <b-button class="mb-2" variant="primary" type="submit" :disabled="loading" block>
              <b-spinner small v-if="loading" style="margin-bottom:2px;"></b-spinner> Submit
            </b-button>
            <b-link to="/login">Go back to Login</b-link>
          </b-form-group>
        </b-form>
      </v-validation-observer>
    </b-card>
  </div>
</div>
</template>

<script>
import api from '../services/apiService'
export default {
  name: "Signup",
  data: () => ({
    form: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      confirmPassword: ''
    },
    loading: false,
    alert: null
  }),
  methods: {
    async onSubmit() {
      this.loading = true
      try {
        let response = await api.post('/api/users', JSON.stringify(this.form))
        api.defaults.headers.common['x-auth-token'] = response.data.token;
        localStorage.setItem('auth', JSON.stringify(response.data));
        this.$store.state.auth = response.data
        this.$router.push('/')
      } catch (error) {
        this.alert = { type: 'danger', message: error.data.message  }
      }finally{
        this.loading = false
      }
    }
  }
}
</script>